import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import Colors from '../utils/colors.json'
import HeaderButton from './HeaderButton'
import GM_logo from '../img/GM_logo_white.svg'
import Hamburger from 'hamburger-react'
import { getAuth, signOut } from 'firebase/auth'
import { IsAdminContext } from '../App'

const Container = styled.div`
  display: flex; 
  flex-direction: row;
  justify-content: center;
  position: fixed;
  width: 100vw;
  height: 52px;
  background: linear-gradient(180deg, rgba(3, 43, 50, 0.3) 0%, rgba(0,0,0,0) 100%);
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  z-index: 100000;
`

export const headerHeight = 52

const Limiter = styled.div`
  position: fixed;
  max-width: 1800px;
  width: 100vw;
  height: ${headerHeight}px;
  display: flex; 
  flex: 1;
  z-index: 100000;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 600px){
    flex-direction: column;
    width: 100%;
    top: 0px;
    align-items: normal;
  }
`
const LogoText = styled.img`
  width: 300px;
  padding: 15px 25px;
  box-sizing: border-box;
  @media only screen and (max-width: 600px){
    padding: 15px 25px;
    width: 60%;
  }
`

const Spacer1 = styled.div`
  flex: 1;
  @media only screen and (max-width: 600px){
      flex: 2;
  }
`
const Spacer2 = styled.div`
  flex: 2;
  @media only screen and (max-width: 600px){
      flex: 1;
  }
`

const StyledLink = styled(Link)`
  position: relative;
  display: flex;
  height: 58px;
  transition: 300ms;
  @media only screen and (max-width: 600px){
    z-index: 0;
    background-color: rgba(0,0,0, .15);  
    backdrop-filter: blur(5px);
  }
`
const HamburgerWrapper = styled.div`
  display: none;
  position: absolute;
  right: 0;
  padding: 3px 30px;
  color: ${Colors.GM_Solid_White};
  @media only screen and (max-width: 600px){
    display: flex;
  }
`
const MobileMenu = styled.div<{ open: boolean }>`
  display: flex;
  
  @media only screen and (max-width: 600px){
    overflow-y: ${(props: { open: boolean }) => props.open ? 'hidden' : 'scroll'};
    transition: 450ms;
    position: fixed;
    background-color: ${(props: { open: boolean }) => props.open ? Colors.GM_Black1 : Colors.GM_Solid_Black};
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    height: 100vh;
    width: 100%;
    top: ${(props: { open: boolean }) => props.open ? '0' : '-100vh'};
    flex: 1;
    z-index: -2;
    padding-bottom: ${headerHeight}px;
  }
`

const Header: React.FC = () => {
  const [isOpen, setOpen] = useState(true)
  const navigate = useNavigate()
  const isAdmin = useContext(IsAdminContext)
  const auth = getAuth()

  const handleSignOut = (): void => {
    signOut(auth).then(() => {
      // Sign-out successful.
      navigate('/')
    }).catch((error) => {
      // An error happened.
      console.log(error)
    })
  }

  const location = useLocation()
  useEffect(() => {
    setOpen(false)
  }, [location])

  return (
    <Container>

      <Limiter>
        <StyledLink to='/'>
          <LogoText src={GM_logo} />
        </StyledLink>
        <Spacer2 />
        <MobileMenu open={isOpen}>

          <HeaderButton onClick={() => { navigate('/') }} active={location.pathname === '/'}>Home</HeaderButton>
          <HeaderButton onClick={() => { navigate('/case') }} active={location.pathname.includes('case')}>Case </HeaderButton>
          <HeaderButton onClick={() => { navigate('/about') }} active={location.pathname.includes('about')}> About</HeaderButton>

          {isAdmin && <HeaderButton active={window.location.href.includes('admin')} onClick={() => { navigate('/admin') }}>Admin</HeaderButton>}

          {isAdmin && <HeaderButton active={false} onClick={handleSignOut}>Logout</HeaderButton>}
        </MobileMenu>

        <Spacer1 />

        <HamburgerWrapper onClick={() => setOpen(!isOpen)}>
          <Hamburger rounded toggled={isOpen} toggle={setOpen} />
        </HamburgerWrapper>

      </Limiter>

    </Container>
  )
}

export default Header
